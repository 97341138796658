import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Blog } from "../../../../interfaces/Blog";
import DOMPurify from "dompurify";
import { REACT_APP_URL } from "../../../../config";

export const BlogDetails = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState<Blog | null>(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        console.log(slug)
        const response = await fetch(
          `${REACT_APP_URL}/blogs/rentdigi/fetch_blog/${slug}`
        );
        if (response.ok) {
          const res = await response.json();
          setBlog(res.data);
        } else {
          console.error("Error fetching blog:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };

    fetchBlog();
  }, [slug]);

  if (!blog) {
    return <></>;
  }

  const sanitizedContent = DOMPurify.sanitize(String(blog?.content));

  return (
    <div className="blog-details">
      <div className="container">
        <div
          className="blg-dtl-img"
          style={{ backgroundImage: `url(${blog?.image["0"]})` }}
        ></div>
        <h2>{blog?.title}</h2>
        <h2>Blog</h2>
        <div dangerouslySetInnerHTML={{ __html: sanitizedContent }}></div>
      </div>
    </div>
  );
};
