import React from 'react';

export const CmnHero = () => {
  const backgroundImageUrl = 'images/about-us-bg.png';

  return (
    <div className="cmn-hero" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
      <div className="container">
        <div className="cmn-hero-inner">
          <h2 style={{paddingLeft: "35px"}}>About us</h2>
        </div>
      </div>
    </div>
  );
};
