import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../utils/helpers";

export const CompanyMotoSection = () => {
  return (
    <div className="section-one ">
      <div className="dark-blue-bg cmn-padd">
        <div className="container">
          <div className="row-flex align-items-center">
            <div className="col-md-5">
              <div className="cmn-left">
                <h3 className="color-white">
                  Are you tired of dealing with the constant stress and hassle
                  that comes with managing your rental business? 
                </h3>
                <h3 className="color-white">
                  Are you
                  searching for effective solutions to increase your rental
                  income and streamline your property management?
                </h3>
                <h4 className="color-white">Look no further! Rentdigicare is here to 
                address your pain points and provide you with the solutions you need.</h4>
                <div className="cmn-brd justify-start">
                  <span className="fst-brd"></span>
                  <span className="secend-brd"></span>
                </div>
                <p className="color-white">
                  <strong style={{ color: "white" }}>
                    Automated Rental Process
                  </strong>
                </p>
                <p className="color-white">
                  <strong style={{ color: "white" }}>
                    Hassle-Free Maintenance
                  </strong>
                </p>
                <p className="color-white">
                  <strong style={{ color: "white" }}>
                    Efficient Rent Collection and Financial Management
                  </strong>
                </p>
                <Link to="/contact-us">
                  <a className="btn orange-btn" href="#">
                    Get Started
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_19_66)">
                        <path
                          d="M12.5 4.16675L11.0312 5.6355L16.8437 11.4584H4.16666V13.5417H16.8437L11.0312 19.3647L12.5 20.8334L20.8333 12.5001L12.5 4.16675Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_19_66">
                          <rect width="25" height="25" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-md-7">
              <div className="cmn-right">
                <img src={toAbsoluteUrl("images/img-1.png")} alt="Image 1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
