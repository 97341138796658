import React from "react";

export const BreadCrumps = () => {
  return (
    <div className="breadcrums-main">
      <div className="container">
        <ul className="breadcrums">
          <li>
            <a href="#">
              Blog{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <g clip-path="url(#clip0_168_608)">
                  <path
                    d="M8.948 17.2812L13.7188 12.5L8.948 7.71875L10.4167 6.25L16.6667 12.5L10.4167 18.75L8.948 17.2812Z"
                    fill="#FF6128"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_168_608">
                    <rect width="25" height="25" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </li>
          <li>10 Things All Rooms Should Have</li>
        </ul>
      </div>
    </div>
  );
};
