import React from "react";

export const ManagementSection = () => {
  return (
    <div className="section-two cmn-padd">
      <div className="container">
        <div className="row-flex align-items-center">
          <div className="col-md-6">
            <div className="cmn-right">
              <img src="images/Group 7@2x.png" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="cmn-left pd-left">
              <h3>
                We streamline the management process, making it a wise decision
                for property owners. Here are some compelling reasons to
                consider our property management services:
              </h3>
              <div className="cmn-brd justify-start">
                <span className="fst-brd"></span>
                <span className="secend-brd"></span>
              </div>
              <p>
                <strong>Time and Effort Saving:</strong> We handle day-to-day
                operations, such as tenant screening, rent collection, and
                maintenance requests. This frees up you (landlords) from
                time-consuming tasks, allowing them to focus on other priorities
                or invest in additional properties.
              </p>
              <p>
                <strong>Expertise and Experience:</strong> We have extensive
                experience in managing properties. We possess the knowledge of
                local rental markets, legal regulations, and best practices,
                ensuring that your property is managed efficiently and in
                compliance with relevant laws.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
