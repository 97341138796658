import React from "react";

const TermsofService = () => {
  return (
    <div className="abt_section cmn-padd">
      <div className="container">
        <div className="head-cmn">
          <h2>Terms and Conditions</h2>
          <div className="cmn-brd">
            <span className="fst-brd"></span>
            <span className="secend-brd"></span>
            <span className="fst-brd"></span>
            <span className="secend-brd"></span>
          </div>
        </div>
        <div style={{ marginTop: "15px", textAlign: "left" }}>
          <strong>
            <p style={{ color: "black" }}>
              Welcome to www.rentdigicare.com, a software management portal
              operated by RentDigiCare based in Canada. By using this website,
              you agree to comply with and be bound by the following terms and
              conditions of use. Please read these terms carefully before using
              the site.
              <strong>
                Please read these terms and conditions carefully before using
                Our Service.
              </strong>
            </p>
          </strong>
          <p>
            <strong style={{ color: "black" }}>
              <li>Interpretation:</li>
            </strong>
            <br />
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>Definitions:</li>
            </strong>
            <br />
            For the purposes of these Terms and Conditions
            <ul>
              <li>
                Affiliate means an entity that controls, is controlled by or is
                under common control with a party, where "control" means
                ownership of 50% or more of the shares, equity interest or other
                securities entitled to vote for election of directors or other
                managing authority
              </li>
              <li>Country refers to: Alberta, Canada</li>
              <li>
                Company (referred to as either "the Company", "We", "Us" or
                "Our" in this Agreement) refers to Rentdigicare Corp, Edmonton,
                Alberta, Canada.
              </li>
              <li>
                Device means any device that can access the Service such as a
                computer, a cellphone or a digital tablet.
              </li>
              <li>Service refers to the Website.</li>
              <li>
                Terms and Conditions (also referred as "Terms") mean these Terms
                and Conditions that form the entire agreement between You and
                the Company regarding the use of the Service. This Terms and
                Conditions agreement has been created with the help of the Terms
                and Conditions Generator.
              </li>
              <li>
                Third-party Social Media Service means any services or content
                (including data, information, products or services) provided by
                a third-party that may be displayed, included or made available
                by the Service.
              </li>
              <li>
                Website refers to Rendigicare, accessible from
                https://rentdigicare.com/
              </li>
              <li>
                You means the individual accessing or using the Service, or the
                company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </li>
            </ul>
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>
                Information we collect from landlords may include (but is not
                limited to):
              </li>
            </strong>
            <br />
            <ul>
              <strong style={{ color: "black" }}>
                <li>1. Acceptance of Terms</li>
              </strong>
              <li>
                By accessing or using www.rentdigicare.com, you agree to be
                bound by these Terms of Use, as well as all applicable laws and
                regulations. If you do not agree with any of these terms, you
                are prohibited from using or accessing this site.
              </li>
              <br />
              <strong style={{ color: "black" }}>
                <li>2. Use of the Website</li>
              </strong>
              <li>
                www.rentdigicare.com is a software management portal designed
                for landlords, maintenance teams, and managers to streamline the
                rental property management process. You may use this website for
                your own personal and non-commercial use, subject to these terms
                and conditions.
              </li>
              <br />
              <strong style={{ color: "black" }}>
                <li>3. User Account</li>
              </strong>
              <li>
                To access certain features of www.rentdigicare.com, you may be
                required to create a user account. You are responsible for
                maintaining the confidentiality of your account and password and
                for restricting access to your computer. You agree to accept
                responsibility for all activities that occur under your account
                or password.
              </li>
              <br />
              <strong style={{ color: "black" }}>
                <li>4. Intellectual Property</li>
              </strong>
              <li>
                All content on www.rentdigicare.com, including but not limited
                to text, graphics, logos, images, and software, is the property
                of RentDigiCare or its content suppliers and is protected by
                Canadian and international copyright laws.
              </li>
              <li>
                You may not modify, copy, distribute, transmit, display,
                perform, reproduce, publish, license, create derivative works
                from, transfer, or sell any information or software obtained
                from www.rentdigicare.com
              </li>
              <br />
              <strong style={{ color: "black" }}>
                <li>5. Prohibited Use</li>
              </strong>
              <li>
                You must not use www.rentdigicare.com in any way that causes, or
                may cause, damage to the website or impairment of the
                availability or accessibility of www.rentdigicare.com, or in any
                way which is unlawful, illegal, fraudulent, or harmful.{" "}
              </li>
              <li>
                You must not use this website to copy, store, host, transmit,
                send, use, publish, or distribute any material which consists of
                (or is linked to) any spyware, computer virus, Trojan horse,
                worm, keystroke logger, rootkit, or other malicious computer
                software.
              </li>
              <br />
              <strong style={{ color: "black" }}>
                <li>6. Links to Other Websites</li>
              </strong>
              <li>
                www.rentdigicare.com may contain links to third-party websites
                or services that are not owned or controlled by RentDigiCare. We
                have no control over, and assume no responsibility for, the
                content, privacy policies, or practices of any third-party
                websites or services.
              </li>
              <br />
              <strong style={{ color: "black" }}>
                <li>7. Limitation of Liability</li>
              </strong>
              <li>
                In no event shall RentDigiCare be liable for any damages,
                including without limitation, damages for loss of data or
                profit, or due to business interruption, arising out of the use
                of or inability to use www.rentdigicare.com.
              </li>
              <br />
              <strong style={{ color: "black" }}>
                <li>8. Changes to Terms</li>
              </strong>
              <li>
                RentDigiCare reserves the right to revise these terms of use at
                any time without notice. By using this website, you agree to be
                bound by the current version of these terms of use.{" "}
              </li>
              <br />
              <strong style={{ color: "black" }}>
                <li>9. Governing Law</li>
              </strong>
              <li>
                These terms and conditions are governed by the laws of Canada
                and you irrevocably submit to the exclusive jurisdiction of the
                courts in that country. <br />
                By using www.rentdigicare.com, you hereby consent to our terms
                of use and agree to abide by them.
              </li>
              <br />
              <strong style={{ color: "black" }}>
                <li>10. Contact Us</li>
              </strong>
              <li>
                If you have any questions or concerns about these terms and
                conditions, please contact us at help@rentdigi.com{" "}
              </li>
            </ul>
          </p>

          <div style={{ textAlign: "center", padding: "20px" }}>
            <p style={{ fontSize: "1rem", lineHeight: "1.5" }}>
              Thank you for choosing <strong>www.rentdigicare.com</strong> as
              your trusted partner for property management. We trust you'll
              discover its immense value for your needs.
            </p>

            <div
              style={{
                textAlign: "right",
                backgroundColor: "#f4f4f4",
                padding: "10px",
              }}
            >
              <small style={{ color: "#666" }}>
                Last updated: January 26, 2024
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TermsofService };
