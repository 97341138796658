import React from 'react';

export const CmnHero = () => {
  // const backgroundImageUrl = 'images/about-us-bg.jpg';
  const backgroundImageUrl = 'images/about-us-bg2.jpg';

  return (
    <div className="cmn-hero" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
      <div className="container">
        <div className="cmn-hero-inner">
          {/* <h2 style={{paddingLeft: "35px"}}>Our Services</h2> */}
        </div>
      </div>
    </div>
  );
};
