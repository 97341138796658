import React from 'react'

export const PageNotFound = () => {
  return (
    <div className="abt_section cmn-padd">
        <div className="container">
          <div className="head-cmn">
            <h2>
              404 - Page Not Found
            </h2>
            <div className="cmn-brd">
              <span className="fst-brd"></span>
              <span className="secend-brd"></span>
              <span className="fst-brd"></span>
              <span className="secend-brd"></span>
            </div>
          </div>
        </div>
      </div>
  )
}
