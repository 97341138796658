import React from "react";

export const DescriptorSection = () => {
  return (
    <div className="section-four cmn-padd" style={{background: "#F7F8F9"}}>
      <div className="container">
        <div className="row-flex align-items-center">
          <div className="col-md-7">
            <div className="cmn-right">
              <img src="images/Group 39.png" />
            </div>
          </div>
          <div className="col-md-5">
            <div className="cmn-left pd-left">
              <h3>Why Go digitally automated?</h3>
              <div className="cmn-brd justify-start">
                <span className="fst-brd"></span>
                <span className="secend-brd"></span>
              </div>
              <p>
                <strong>Streamlined Communication:</strong> Automation tools can
                facilitate seamless communication between landlords, tenants,
                and property managers.
              </p>
              <p>
                <strong>Efficient Rent Collection:</strong> Automated rent
                collection systems enable tenants to make payments online,
                reducing the need for manual collection efforts.
              </p>
              <p>
                <strong>Reduced Human Error:</strong> Automation minimizes the
                risk of human errors in managing tasks like lease agreements,
                calculations, and record-keeping.
              </p>
              <p>
                <strong>Consistent Compliance:</strong> Property management
                regulations can be complex and vary by location.{" "}
                {/* Automated systems can help landlords stay compliant with local laws and regulations, reducing the risk of legal issues. */}
              </p>
              <p>
                <strong>Improved Tenant Screening:</strong> Automated tenant
                screening processes can quickly verify applicant information,
                credit checks, and rental histories, leading to better tenant
                selection.
              </p>
              <p>
                <strong>Maintenance Tracking:</strong> Automating maintenance
                requests and tracking helps ensure that repair and maintenance
                issues are addressed promptly.
              </p>
            </div>
          </div>

          {/* <div className="col-md-3 ">
            <div className="cmn-left">
              <h3>Why Go digitally automated?</h3>
              <div className="cmn-brd justify-start">
                <span className="fst-brd"></span>
                <span className="secend-brd"></span>
              </div>
              <p>
                <strong>Streamlined Communication:</strong> Automation tools can
                facilitate seamless communication between landlords, tenants,
                and property managers. This ensures that everyone stays
                informed, reducing misunderstandings and improving tenant
                satisfaction.
              </p>
              <p>
                <strong>Efficient Rent Collection:</strong> Automated rent
                collection systems enable tenants to make payments online,
                reducing the need for manual collection efforts. This
                streamlines the rent collection process and improves cash flow
                for landlords.
              </p>
              <p>
                <strong>Reduced Human Error:</strong> Automation minimizes the
                risk of human errors in managing tasks like lease agreements,
                calculations, and record-keeping. This leads to more accurate
                and reliable data management.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="cmn-right">
              <img src="images/img-4.png" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="cmn-left pd-left">
              <p>
                <strong>Consistent Compliance:</strong> Property management
                regulations can be complex and vary by location. Automated
                systems can help landlords stay compliant with local laws and
                regulations, reducing the risk of legal issues.
              </p>
              <p>
                <strong>Improved Tenant Screening:</strong> Automated tenant
                screening processes can quickly verify applicant information,
                credit checks, and rental histories, leading to better tenant
                selection and reduced risk of problematic tenants.
              </p>
              <p>
                <strong>Maintenance Tracking:</strong> Automating maintenance
                requests and tracking helps ensure that repair and maintenance
                issues are addressed promptly, prolonging the life of property
                assets and enhancing tenant satisfaction.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
