import React from 'react';

export const CounterSection = () => {
  return (
    <div className="counter-section dark-blue-bg cmn-padd">
      <div className="container">
        <div className="row-flex">
          <div className="col-md-3">
            <div className="counter-bx">
              <div className="counter-img">
                <img src="images/cnt-1.svg" alt="Counter 1" />
              </div>
              <div className="counter-txt">
                <h3>245+</h3>
                <p>Active Landlords</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="counter-bx">
              <div className="counter-img">
                <img src="images/cnt-2.svg" alt="Counter 2" />
              </div>
              <div className="counter-txt">
                <h3>625+</h3>
                <p>Third Party Vendor</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="counter-bx">
              <div className="counter-img">
                <img src="images/cnt-3.svg" alt="Counter 3" />
              </div>
              <div className="counter-txt">
                <h3>1250+</h3>
                <p>Active Tenants</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="counter-bx">
              <div className="counter-img">
                <img src="images/cnt-4.svg" alt="Counter 4" />
              </div>
              <div className="counter-txt">
                <h3>485+</h3>
                <p>Property Managers</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
