import { CmnHero } from "./Subcomponents/CmnHero";
import BlogListing from "./Subcomponents/BlogListing";

export const Blog = () => {
  return (
    <>  
      <CmnHero/>
      <BlogListing/>
    </>
  )
}
