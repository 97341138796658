import React, { useState } from "react";
import { REACT_APP_URL } from "../../../config";

export const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${REACT_APP_URL}/newsletters/rentdigi/newsletter/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        }
      );

      if (response.status === 200) {
        setSuccess("Thank you for subscribing");
        setEmail("");
        setTimeout(() => {
          setSuccess("");
        }, 1500);
      } else {
        setError("Subscription error. Please try again later");
        setEmail("");
        setTimeout(() => {
          setError("");
        }, 1500);
      }
    } catch (error) {
      console.log(error);
      setError("Subscription error. Please try again later");
      setEmail("");
      setTimeout(() => {
        setError("");
      }, 1500);
    }
  };

  return (
    <div className="newsletter">
      <div className="container">
        <div className="newsletter-main dark-orange-bg">
          <div className="row-flex align-items-center">
            <div className="col-md-6">
              <div className="news-left">
                <h3>Sign Up for Our Newsletter</h3>
              </div>
            </div>
            <div className="col-md-6">
              <div className="nws-form">
                {success && (
                  <p style={{ color: "white", fontWeight: "bold" }}>
                    {success}
                  </p>
                )}
                {error && (
                  <p style={{ color: "white", fontWeight: "bold" }}>{error}</p>
                )}
                <form onSubmit={handleSubmit}>
                  <div className="nws-field">
                    <input
                      type="email"
                      placeholder="Enter Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button className="sbc-btn">Subscribe</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
