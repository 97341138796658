import React from "react";

export const MapSection = () => {
  return (
    <>
      <div className="ctt-dtl">
        <div className="container">
          <div className="ctt-dtl-inner">
            <div className="row-flex">
              <div className="col-med-4">
                <div className="ctt_box">
                  <div className="ctt_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                      fill="none"
                    >
                      {" "}
                      <g clip-path="url(#clip0_168_112)">
                        {" "}
                        <path
                          d="M29.1813 22.4292C27.3875 22.4292 25.6521 22.1375 24.0333 21.6125C23.5229 21.4375 22.9542 21.5688 22.5604 21.9625L20.2708 24.8354C16.1438 22.8667 12.2792 19.1479 10.2229 14.875L13.0667 12.4542C13.4604 12.0458 13.5771 11.4771 13.4167 10.9667C12.8771 9.34792 12.6 7.6125 12.6 5.81875C12.6 5.03125 11.9438 4.375 11.1563 4.375H6.11042C5.32292 4.375 4.375 4.725 4.375 5.81875C4.375 19.3667 15.6479 30.625 29.1813 30.625C30.2167 30.625 30.625 29.7063 30.625 28.9042V23.8729C30.625 23.0854 29.9688 22.4292 29.1813 22.4292Z"
                          fill="white"
                        />{" "}
                      </g>{" "}
                      <defs>
                        {" "}
                        <clipPath id="clip0_168_112">
                          {" "}
                          <rect width="35" height="35" fill="white" />{" "}
                        </clipPath>{" "}
                      </defs>{" "}
                    </svg>
                  </div>
                  <div className="ctt_text">
                    <p>Talk To Us</p>
                    <div className="ct_p">
                      <a href="tel:+1-844-999-3444">+1-844-999-3444</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-med-4">
                <div className="ctt_box">
                  <div className="ctt_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                      fill="none"
                    >
                      {" "}
                      <g clip-path="url(#clip0_168_129)">
                        {" "}
                        <path
                          d="M29.1666 5.83331H5.83329C4.22913 5.83331 2.93121 7.14581 2.93121 8.74998L2.91663 26.25C2.91663 27.8541 4.22913 29.1666 5.83329 29.1666H29.1666C30.7708 29.1666 32.0833 27.8541 32.0833 26.25V8.74998C32.0833 7.14581 30.7708 5.83331 29.1666 5.83331ZM29.1666 26.25H5.83329V11.6666L17.5 18.9583L29.1666 11.6666V26.25ZM17.5 16.0416L5.83329 8.74998H29.1666L17.5 16.0416Z"
                          fill="white"
                        />{" "}
                      </g>{" "}
                      <defs>
                        {" "}
                        <clipPath id="clip0_168_129">
                          {" "}
                          <rect width="35" height="35" fill="white" />{" "}
                        </clipPath>{" "}
                      </defs>{" "}
                    </svg>
                  </div>
                  <div className="ctt_text">
                    <p>Send Us Email</p>
                    <div className="ct_p">
                      <a href="mailto:help@rentdigi.com">help@rentdigi.com</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-med-4">
                <div className="ctt_box">
                  <div className="ctt_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                      fill="none"
                    >
                      {" "}
                      <g clip-path="url(#clip0_168_140)">
                        {" "}
                        <path
                          d="M17.5 2.91669C11.8562 2.91669 7.29163 7.48127 7.29163 13.125C7.29163 20.7813 17.5 32.0834 17.5 32.0834C17.5 32.0834 27.7083 20.7813 27.7083 13.125C27.7083 7.48127 23.1437 2.91669 17.5 2.91669ZM17.5 16.7709C15.4875 16.7709 13.8541 15.1375 13.8541 13.125C13.8541 11.1125 15.4875 9.47919 17.5 9.47919C19.5125 9.47919 21.1458 11.1125 21.1458 13.125C21.1458 15.1375 19.5125 16.7709 17.5 16.7709Z"
                          fill="white"
                        />{" "}
                      </g>{" "}
                      <defs>
                        {" "}
                        <clipPath id="clip0_168_140">
                          {" "}
                          <rect width="35" height="35" fill="white" />{" "}
                        </clipPath>{" "}
                      </defs>{" "}
                    </svg>
                  </div>
                  <div className="ctt_text">
                    <p>Our Location</p>
                    <div className="ct_p">Edmonton, Calgary, Canada</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="map-section">
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6495308.4369588!2d-116.84257668023791!3d53.756106909984005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a0224580deff23%3A0x411fa00c4af6155d!2sEdmonton%2C%20AB%2C%20Canada!5e0!3m2!1sen!2sin!4v1695810706094!5m2!1sen!2sin"
          width="100%"
          height="420"
          style={{ border: "0" }}
          allowFullScreen={false}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};
