import React from 'react';

const AboutSection = () => {
  return (
    <div className="abt_section cmn-padd">
      <div className="container">
        <div className="head-cmn">
          <h2>Your Trusted Partner in Rental Property Care: Experience and Excellence in Maintenance</h2>
          <div className="cmn-brd">
            <span className="fst-brd"></span>
            <span className="secend-brd"></span>
          </div>
        </div>
        <div className="row-flex align-items-center">
          <div className="col-md-6">
            <div className="cmn-right">
              <img src="images/maintenance.png" alt="Maintenance" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="cmn-left pd-left">
              <h2>Maintenance Online</h2>
              <div className="cmn-brd justify-start">
                <span className="fst-brd"></span>
                <span className="secend-brd"></span>
              </div>
              <p>
                No more tracking maintenance via calls, text, email, excel or paperwork. All online and assign to 3rd
                party vendors also or internal maintenance team.
              </p>
              <p>
                Resident can also see live update on each service requested. As a property owner you have birds eye view
                on each property to each suite, cost control for repair and management.
              </p>
              <div className="abt_opt d-flex flex-wrap">
                <div className="abt_opt_fx d-flex flex-wrap">
                  <div className="ab-ic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                      <g clipPath="url(#clip0_195_561)">
                        <path
                          d="M39.375 22.9125C39.375 12.6188 31.3875 5.625 22.5 5.625C13.7063 5.625 5.625 12.4687 5.625 23.025C4.5 23.6625 3.75 24.8625 3.75 26.25V30C3.75 32.0625 5.4375 33.75 7.5 33.75H9.375V22.3125C9.375 15.0562 15.2438 9.1875 22.5 9.1875C29.7563 9.1875 35.625 15.0562 35.625 22.3125V35.625H20.625V39.375H35.625C37.6875 39.375 39.375 37.6875 39.375 35.625V33.3375C40.4813 32.7563 41.25 31.6125 41.25 30.2625V25.95C41.25 24.6375 40.4813 23.4937 39.375 22.9125Z"
                          fill="#0C518A"
                        />
                        <path
                          d="M16.875 26.25C17.9105 26.25 18.75 25.4105 18.75 24.375C18.75 23.3395 17.9105 22.5 16.875 22.5C15.8395 22.5 15 23.3395 15 24.375C15 25.4105 15.8395 26.25 16.875 26.25Z"
                          fill="#0C518A"
                        />
                        <path
                          d="M28.125 26.25C29.1605 26.25 30 25.4105 30 24.375C30 23.3395 29.1605 22.5 28.125 22.5C27.0895 22.5 26.25 23.3395 26.25 24.375C26.25 25.4105 27.0895 26.25 28.125 26.25Z"
                          fill="#0C518A"
                        />
                        <path
                          d="M33.7501 20.6812C32.8501 15.3375 28.2001 11.25 22.5939 11.25C16.9126 11.25 10.8001 15.9562 11.2876 23.3438C15.9189 21.45 19.4064 17.325 20.4001 12.3C22.8564 17.2313 27.9001 20.625 33.7501 20.6812Z"
                          fill="#0C518A"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_195_561">
                          <rect width="45" height="45" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="ab-txt">
                    <p>Excellent 24/7 Support</p>
                  </div>
                </div>
                <div className="abt_opt_fx d-flex flex-wrap">
                  <div className="ab-ic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                      <g clipPath="url(#clip0_195_582)">
                        <path
                          d="M40.1437 21.7125L23.2687 4.8375C22.5938 4.1625 21.6562 3.75 20.625 3.75H7.5C5.4375 3.75 3.75 5.4375 3.75 7.5V20.625C3.75 21.6562 4.1625 22.5938 4.85625 23.2875L21.7313 40.1625C22.4062 40.8375 23.3438 41.25 24.375 41.25C25.4062 41.25 26.3438 40.8375 27.0187 40.1437L40.1437 27.0187C40.8375 26.3438 41.25 25.4062 41.25 24.375C41.25 23.3438 40.8187 22.3875 40.1437 21.7125ZM10.3125 13.125C8.75625 13.125 7.5 11.8687 7.5 10.3125C7.5 8.75625 8.75625 7.5 10.3125 7.5C11.8687 7.5 13.125 8.75625 13.125 10.3125C13.125 11.8687 11.8687 13.125 10.3125 13.125Z"
                          fill="#0C518A"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_195_582">
                          <rect width="45" height="45" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="ab-txt">
                    <p>Premium Quality Offers</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
