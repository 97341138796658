import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout } from '../components/layouts/Layout';
import { PageNotFound } from '../components/modules/404/PageNotFound';
import { ContactUs } from '../components/subpages/contact-us/ContactUs';
import { Blog } from '../components/subpages/blog/blog';
import { BlogDetail } from '../components/subpages/blog-detail/BlogDetail';
import { Services } from '../components/subpages/about-us/aboutus';
import { Landing } from '../components/subpages/landing-page/Landing';
import { About } from '../components/subpages/about/about';
import { PrivacyPolicy } from '../components/subpages/privacy-policy/PrivacyPolicy';
import {TermsofService} from '../components/subpages/terms-of-service/TermsofService';

const Routing: React.FC = () => {
  
  return (
    <Router basename="/">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Landing />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog-detail/:slug" element={<BlogDetail />} />
          <Route path="services" element={<Services />} />
          <Route path="about" element={<About />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-service" element={<TermsofService />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default Routing;