import React from "react";

const AboutSection = () => {
  return (
    <>
      <div className="abt_section cmn-padd">
        <div className="container">
          <div className="head-cmn">
            <h2>
              Welcome to Rentdigicare, your digital solution for efficient
              rental property management.
            </h2>
            <div className="cmn-brd">
              <span className="fst-brd"></span>
              <span className="secend-brd"></span>
              <span className="fst-brd"></span>
              <span className="secend-brd"></span>
            </div>
          </div>
        </div>
      </div>

      <div className="abt_section cmn-padd">
        <div className="container">
          <div className="outer-container">
            <div className="inner-container">
              <div className="content">
                <h1 style={{ color: "white", maxWidth: "565px" }}>
                  The Ultimate Platform for Property Management and
                  Tenant Satisfaction
                </h1>
                <p style={{ color: "#8DCBFF", maxWidth: "685px" }}>
                  Our platform was founded with the vision of streamlining and
                  simplifying the rental property management process in a
                  digital ecosystem. We understand the challenges faced by
                  property owners, landlords, and tenants alike, and we are
                  dedicated to providing innovative tools and services to make
                  the entire rental experience hassle-free. At the core of Rentdigicare is the passion and drive of our founder, Sunny
                  Dihana. Sunny recognized the need for a digital platform that
                  could revolutionize the way rental properties are managed.
                </p>
                <div className="section_brd">
                  <span className="first_brd"></span>
                  <span className="second_brd"></span>
                </div>
              </div>
              <div className="image">
                <img src="images/Group 78@2x.png" alt="Image" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="abt_section cmn-padd">
        <div className="container">
          <div className="row-flex align-items-center column-reversed-mb">
            <div className="col-md-6">
              <div className="cmn-left pd-right">
                <div className="cmn-brd justify-start">
                  <span className="fst-brd"></span>
                  <span className="secend-brd"></span>
                </div>
                <p style={{ color: "#434343" }}>
                  <b>Meet Sunny,</b> the visionary behind Rentdigicare. One day
                  Sunny was sitting by his family, savoring those precious
                  moments with his twins filled with laughter and joy. Amidst
                  all this, his phone incessantly buzzed with messages, calls,
                  and emails, all urging him to schedule rental viewings. With
                  each interruption, Sunny yearned for a simpler way, a way to
                  streamline the rental process and reclaim his uninterrupted
                  family time.Sunny Dihana firmly believes in the importance of
                  saving paper and protecting the environment.
                </p>
                <p style={{ color: "#434343" }}>
                  In that transformative moment, inspiration struck like a
                  lightning bolt. A groundbreaking idea took hold in{" "}
                  <em>
                    <b>
                      Sunny's mind. "Why not create an innovative rental
                      management system,"
                    </b>
                  </em>
                  , one that would revolutionize the way people find and secure
                  their dream rentals!{" "}
                </p>
                <p style={{ color: "#434343" }}>
                  Drawing from his extensive background in property management
                  and his firsthand experience with the exasperations endured by
                  property managers, landlords, third-party contractors, and
                  maintenance personnel, Sunny envisions Rentdigicare. Driven by
                  his vision. Sunny's vision is filled with empathy and
                  compassion for everyone engaged in the renting process's
                  struggles and frustrations. He intends to make renting a house
                  easy so people can spend more time on what's important. The
                  platform empowers property managers and landlords with an
                  arsenal of tools and services aimed at enhancing their
                  efficiency and reducing their stress.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="cmn-right">
                <img src="images/Group 91@2x.png" alt="Fingertips" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="abt_section cmn-padd">
        <div className="abt_section_3">
          <h1>
            <em>
              <span style={{ fontWeight: "50" }}>From Chaos to Control:</span>
              <br /> Rentdigicare:{" "}
              <span style={{ fontWeight: "50" }}>
                {" "}
                Your Complete Digital Rental Management Solution!{" "}
              </span>
            </em>
          </h1>
          <div className="cmn-brd">
            <span className="fst-brd"></span>
            <span className="secend-brd"></span>
            <span className="fst-brd"></span>
            <span className="secend-brd"></span>
          </div>
          <p style={{ width: "68%", marginTop: "36px", color: "white" }}>
            In addition to our commitment to the environment, Rentdigicare is
            also dedicated to supporting healthcare and government initiatives.
            We understand the significance of the healthcare sector and the
            vital role it plays in our communities. By providing a digital
            rental property management solution, we contribute to the overall
            efficiency of healthcare organizations by reducing administrative
            burdens and allowing healthcare professionals to focus on their core
            responsibilities.
          </p>
        </div>
      </div>

      <div className="abt_section cmn-padd">
        <div className="container">
          <div className="row-flex align-items-center column-reversed-mb">
            <div className="col-md-6">
              <div
                className="cmn-left pd-right"
                style={{ position: "relative" }}
              >
                <h2 style={{ paddingLeft: "63px" }}>
                  Rentdigicare: Bridging Government & Rentals for
                  Seamless Compliance
                </h2>
                <p style={{ paddingLeft: "63px" }}>
                  Furthermore, Rentdigicare works closely with government
                  entities to ensure compliance with regulations and facilitate
                  seamless communication between property owners, tenants, and
                  government agencies. Our platform enables efficient data
                  management, document storage, and reporting, streamlining the
                  interaction between property managers and government
                  departments.
                </p>
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    bottom: "0",
                    width: "14px",
                    borderRadius: "32px",
                    background: "#FF6128",
                  }}
                ></div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="cmn-right">
                <img src="images/Group 80@2x.png" alt="Fingertips" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="abt_section cmn-padd">
        <div className="container">
          <div className="outer-container-2">
            <div className="inner-container-2">
              <div className="content-2">
                <h1 style={{ color: "white", maxWidth: "565px" }}>
                  Unlocking Rental Potential Together!
                </h1>
                <p style={{ color: "#8DCBFF", maxWidth: "685px" }}>
                  We owe a great deal of our success to our exceptional team.
                  Our talented and dedicated professionals play a pivotal role
                  in the development and maintenance of our platform. Their
                  expertise and commitment to excellence drive us forward,
                  ensuring that Rentdigicare remains at the forefront of
                  innovation in rental property management.
                </p>
                <div className="section_brd">
                  <span className="first_brd"></span>
                  <span className="second_brd"></span>
                </div>
              </div>
              <div className="image-2">
                <img src="images/Group 83@2x.png" alt="Image" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="abt_section cmn-padd">
        <div className="container">
          <div className="row-flex align-items-center">
            <div className="col-md-6">
              <div className="cmn-right">
                <img src="images/Group 8@2x.png" alt="Maintenance" />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="cmn-left pd-left"
                style={{ textAlign: "right", position: "relative" }}
              >
                <h2 style={{ paddingRight: "61px" }}>
                  Empowering Excellence Expediting Results !
                </h2>
                <p style={{ marginTop: "31px", paddingRight: "61px" }}>
                  Property managers, landlords, and tenants across the industry
                  love Rentdigicare for its comprehensive features,
                  user-friendly interface, and outstanding customer service. Our
                  platform simplifies their day-to-day operations, automates
                  manual tasks, and provides real-time insights for better
                  decision-making. By utilizing Rentdigicare, property managers
                  can enhance their productivity, streamline processes, and
                  deliver an exceptional experience to their clients.
                </p>
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    width: "14px",
                    borderRadius: "32px",
                    background: "#FF6128",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
