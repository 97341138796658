import React from "react";
import { Carousel } from "react-responsive-carousel";
import OwlCarousel from "react-owl-carousel";

export const Testimonials = () => {
  return (
    <div
      className="testimonial-section cmn-padd"
      style={{ backgroundImage: "url('images/testimonial-bg.svg')" }}
    >
      <div className="container">
        <div className="head-cmn">
          <h2>Testimonials</h2>
          <div className="cmn-brd">
            <span className="fst-brd"></span>
            <span className="secend-brd"></span>
          </div>
          <p style={{color: "black", fontSize: "18px"}}>What our happy Tenant says</p>
        </div>
        <OwlCarousel
          className="testimonial-carousel owl-carousel"
          loop
          margin={10}
          nav
          autoplay={true}
          rewind={true}
          responsiveClass={true}
          autoplayTimeout={7000}
          smartSpeed={800}
          dots={false}
          responsive={{
            0: {
              items: 1,
            },
            600: {
              items: 1,
            },
            1024: {
              items: 1,
            },
            1366: {
              items: 1,
            },
          }}
        >
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/John.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Sonal.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>John</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  First timers to high-rise living and the experience has been
                  top notch. Sona and the management team are gems to deal with.
                  The views are outstanding and the hot tub and gym are just the
                  frosting on the cake. Residents are quiet and living in a
                  concrete makes us feel like safe and secure. I highly
                  recommend this building for anyone looking for that downtown
                  experience and triple A service! (John S.)
                </p>
                <div className="tst-ct">
                  <h4>John, Tenant </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Sonal.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Sonal.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Sonal</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  Rentdigicare has completely transformed my career as a
                  property manager. The resources and support they offer have
                  revolutionized my operations, enabling me to provide
                  outstanding service to both property owners and tenants. With
                  the Rentdigicare platform, I can effortlessly manage
                  properties, track rental income and expenses, and stay on top
                  of all crucial tasks. This has allowed me to enhance my
                  efficiency and deliver exceptional results.
                </p>
                <div className="tst-ct">
                  <h4>Sonal, Property Manager </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Ekrab S.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Vinay.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Ekrab</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  I would like to write a review about the rental company based
                  on my experience with them. I found them to be honest and
                  quick in their service. They made an effort to understand our
                  specific needs and find the most suitable vacancy for us. The
                  maintenance requirements were minimal, but whenever something
                  needed attention, they were extremely prompt in addressing it.
                  Our community is quiet, safe, and well-maintained. I would
                  like to extend my appreciation to Vin Verma, the community
                  leader, who was very helpful throughout the rental process and
                  even after. I am grateful for his assistance.(Ekrab S.)
                </p>
                <div className="tst-ct">
                  <h4>Ekrab, Tenant </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Archie.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Vinay.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Archie</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  GSK company are a very good company and the management as
                  well. Vin are very responsive and reliable and the maintenance
                  team are great. Highly recommended. (Archie C.)
                </p>
                <div className="tst-ct">
                  <h4>Archie, Tenant </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Rhonda.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Vinay.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Rhonda</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  The management here is great thanks to the property manager
                  Vin Verma who went above and beyond to help us to select the
                  best unit according to our requirements. Vin is so
                  professional in terms of dealing with all the situations you
                  face while leaving here and maintenance team is also top
                  notch. Everything is close by and convenient and I like living
                  in this area and having a terrific manager like vin is
                  definitely a peace of mind. I highly recommend chappelle
                  gardens apartments for a place called home to live in.
                  (Rohnda)
                </p>
                <div className="tst-ct">
                  <h4>Rhonda, Tenant </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Courtney.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Vicky.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Courtney</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  Vicky is awesome to deal with. Very caring and accommodating.
                  Excellent place to rent. Very happy with with this company.
                  (Courtney)
                </p>
                <div className="tst-ct">
                  <h4>Courtney, Tenant </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Stormy.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Vicky.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Stormy</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  Love the place. Big bright windows for lot of sunlight. The
                  house is beautiful. Dealing and talking with Vicky is always
                  easy. He is just a phone call or email away. Prompt response
                  and very friendly. Will definitely recommend. (Stormy )
                </p>
                <div className="tst-ct">
                  <h4>Stormy, Tenant </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Vicky.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Vicky.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Vicky</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  The platform that Rentdigicare provides for property managers
                  like myself is a significant advance. It is simple to
                  comprehend and utilize, allowing me to handle all aspects of
                  property management swiftly and effortlessly. The automated
                  Parking and Storage allocation tool is a valuable tool for
                  time savings and equitable distribution. It provides me with
                  accurate and up-to-date information, allowing me to make
                  well-informed decisions regarding rental rates.
                </p>
                <div className="tst-ct">
                  <h4>Vicky, Property Manager </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Asif.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Vicky.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Asif</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  For a property manager, my time with Rentdigicare has been
                  superb. On top of giving me the tools and help to manage my
                  properties, Rentdigicare has gone above and beyond to make my
                  experience smooth. Their software makes it easy to streamline
                  activities, interact with tenants, and handle maintenance
                  concerns.Rentdigicare has made property administration easier
                  and become an essential tool in my daily work.
                </p>
                <div className="tst-ct">
                  <h4>Asif, Property Manager </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Gaduwo.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Asif.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Gaduwo</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  Nova Villa was well maintained, and issue or repairs were
                  always addressed quickly. Asif and the team were always
                  friendly and welcoming, which made living here a positive
                  experience.(Gaduwo )
                </p>
                <div className="tst-ct">
                  <h4>Gaduwo, Tenant </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Vinay.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Asif.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Vinay</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  Since adopting Rentdigicare, my property management activities
                  are more efficient. Their platform is extensive and easy to
                  use. One highlight is automatic parking allocation. I save a
                  lot of time and avoid parking hassles. Store allocation is
                  great too. It's a great tool for keeping track of where things
                  go and making sure they're all in the right places. Now I can
                  see my career graph going up! Thanks Rentdigicare.
                </p>
                <div className="tst-ct">
                  <h4>Vinay, Property Manager </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Reyes.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Asif.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Reyes</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  I would like to take this opportunity to express my sincere
                  gratitude and appreciation for the outstanding services of
                  Property Manager Asif. Asif's dedication to ensuring that
                  tenants are comfortable and happy exceeded my expectations. He
                  always been prompt in responding to my concerns and timely
                  solutions. His approachable and friendly demeanor has made
                  easy for me to discuss any issue. I would highly recommend you
                  to anyone looking for a reliable place to leave. (Reyes)
                </p>
                <div className="tst-ct">
                  <h4>Reyes, Tenant </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Trinity.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Asif.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Trinity</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  The apartment is spacious, clean, and well maintained. The
                  community is quiet and the neighbors are friendly. The
                  location is perfect with plenty of restaurants, shops option.
                  Overall, I am very satisfied with Management. Asif has
                  consistently provide top-notch service and Shawn goes above
                  and beyond to ensure to complete any maintenance request I put
                  in. I highly recommend GSK to anyone in the market for new
                  place to live. (Trinity)
                </p>
                <div className="tst-ct">
                  <h4>Trinity, Tenant </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Mariana.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Mariana.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Mariana</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  I am really thankful for Rentdigicare.com. Maintenance
                  professionals sometimes struggle to locate reliable Tenants
                  and projects. Rentdigicare.com connects me with tenants and
                  managers who require maintenance services, simplifying the
                  process. Communication with tenants directly has made it
                  simpler to understand their demands on the user-friendly
                  platform.
                </p>
                <div className="tst-ct">
                  <h4>Mariana, Maintenance Manager</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Joel.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Joel.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Joel</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  My repair business has changed a lot since I started using
                  Rentdigicare.com. The site has given me a steady stream of
                  good job opportunities that are a good fit for my skills. The
                  ability to show off my skills, licenses, and past jobs has
                  helped me stand out among other repair pros. . I strongly
                  suggest Rentdigicare.com to other repair workers who want to
                  get more Tenants and grow their business.
                </p>
                <div className="tst-ct">
                  <h4>Joel, Maintenance Manager</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Ally.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Ally.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Ally</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                Rentdigicare.com has helped me a lot with my repair business.
                  Not only has the site helped me find new Tenants, but it has
                  also made it easier for me to run tasks. Rentdigicare.com has
                  a wide range of features that make my work easier, to keep
                  track of work progress. I strongly suggest Rentdigicare.com to
                  any repair worker who wants to grow their business and find
                  good Tenants.
                </p>
                <div className="tst-ct">
                  <h4>Ally, Maintenance Manager</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Shawn.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Shawn.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Shawn</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  After exploring several platforms for finding maintenance
                  projects, Rentdigicare.com stands head and shoulders above the
                  rest. The platform's user interface is intuitive and
                  user-friendly, making it effortless to discover relevant job
                  opportunities. The team has been incredibly supportive,
                  promptly addressing all my inquiries and concerns. If you're a
                  maintenance professional seeking a reliable platform to
                  connect with Tenants and grow your business, Rentdigicare.com
                  is the perfect solution.
                </p>
                <div className="tst-ct">
                  <h4>Shawn, Maintenance Manager</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Mykola.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Mykola.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Mykola</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                Rentdigicare.com changed my maintenance business. Rental
                  management capabilities like task tracking and document
                  sharing have improved my productivity and organization. The
                  direct communication route has removed misconceptions and
                  facilitated smooth cooperation. Rentdigicare.com has
                  transformed my capacity to identify new work, demonstrate my
                  knowledge, and provide excellent maintenance services.
                </p>
                <div className="tst-ct">
                  <h4>Mykola, Maintenance Manager</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Jim.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Jim.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Jim</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  Working with Rentdigicare.com was great. I liked their
                  easy-to-use platform for contractors to interact with tenants.
                  Rentdigicare.com answered all my inquiries and concerns
                  quickly. I definitely suggest Rentdigicare.com to contractors
                  seeking a trustworthy and effective business growth platform.
                </p>
                <div className="tst-ct">
                  <h4>Jim, Contractor</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex flex-wrap align-items-center testimonial-main">
              <div className="testimonial-vdo">
                <img src="images/Habib.jpg" />
              </div>
              <div className="testimonial-content">
                <div className="tst-prf d-flex align-items-center">
                  {/* <div className="tst-prf-img ">
                    <img src="images/Habib.jpg" />
                  </div> */}
                  <div className="tst-ct">
                    <h4>Habib</h4>
                    <img src="images/rating.svg" />
                  </div>
                </div>
                <p>
                  I have been using Rentdigicare.com for several months now, and
                  it has significantly streamlined my business operations. The
                  platform provides a user-friendly interface, making it easy to
                  find and bid on relevant projects. Rentdigicare.com has also
                  helped me expand my network by connecting me with quality
                  Tenants. I appreciate the transparency and support provided by
                  the Rentdigicare.com team, and I can confidently say that it
                  has positively impacted my contracting career.
                </p>
                <div className="tst-ct">
                  <h4>Habib, Contractor</h4>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
};
