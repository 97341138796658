import { CmnHero } from "./Subcomponents/CmnHero";
import AboutSection from "./Subcomponents/AboutSection";
import AboutSectionBottom from "./Subcomponents/AboutSectionBottom";
import { Helmet } from "react-helmet";

export const Services = () => {
  return (
    <>
      <Helmet>
        <title>
          Complete Solution For Property Management & Maintenance Services!
        </title>
        <meta
          name="description"
          content="One stop solution for Property management & maintenance needs, from finding rental property, Listings, to Digital Documentation etc. We're a Complete Solution for Rental Management!"
        />
        <meta
          name="keywords"
          content="Property Management, rental property listings, find best rental properties, apartment management services Alberta, leasing property management services"
        />
      </Helmet>
      <CmnHero />
      <AboutSection />
      <AboutSectionBottom />
    </>
  );
};
