import { BreadCrumps } from "./Subcomponents/BreadCrumps";
import { BlogDetails } from "./Subcomponents/BlogDetails";
import { useEffect } from "react";


export const BlogDetail = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  }, [])
  return (
    <>  
      <BreadCrumps/>
      <BlogDetails/>
    </>
  )
}
