import React, { useState } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import { REACT_APP_URL } from '../../../../config';

export const ContactSection = () => {
	const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState('')
  
  const handleSubmit=async(e: React.FormEvent)=>{
    e.preventDefault();
	const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const phoneNumberRegex = /^\d{10,14}$/;
    const isValidEmail = emailRegex.test(email);
    const isValidPhone = phoneNumberRegex.test(phone);
    if(name === ''){
      Swal.fire('Please enter name!')
    }else if(email === ''){
      Swal.fire('Please enter email!')
    }else if(!isValidEmail){
		Swal.fire('Please enter valid email!')
	  }else if(!isValidPhone){
		Swal.fire('Please enter valid phone!')
	  }else if(phone === ''){
      Swal.fire('Please enter phone!')
    }else if(company === ''){
      Swal.fire('Please enter company name!')
    }else{
      let data ={
        name: name,
          email: email,
          phone: phone,
          company: company,
      }
      console.log(data,'data')
      try {
        const response = await axios.post(`${REACT_APP_URL}/leads/admin/addLead`, data);
        console.log(response,'response')
        if (response.data.status === 201) {
		Swal.fire(response.data.message)
          setName("");
          setEmail("");
          setPhone("");
          setCompany("");
        }
      } catch (error) {
        Swal.fire('Something went wrong!')
      }
    }
  }
  return (
    <div className="contact-section cmn-padd">
			<div className="container">
				<div className="head-cmn">
					<h2>Get In Touch With Us</h2>
					<div className="cmn-brd"><span className="fst-brd"></span><span className="secend-brd"></span></div>
					<p>Get in Touch for Hassle-Free Property Maintenance: We're Here to Help</p>
				</div>
				<div className="contact-form">
					<form>
						<div className="d-flex flex-wrap form-rw">
							<div className="half-wd">
								<div className="field-ff">
									<input className="ct-field" value={name} type="text" placeholder="Name" onChange={(e) => setName(e.target.value)}/>
								</div>
							</div>
							<div className="half-wd">
								<div className="field-ff">
									<input className="ct-field" value={phone} type="text" placeholder="Phone Number" onChange={(e) => setPhone(e.target.value)}/>
								</div>
							</div>
							<div className="half-wd">
								<div className="field-ff">
									<input className="ct-field" value={email} type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
								</div>
							</div>
							<div className="half-wd">
								<div className="field-ff">
									<input className="ct-field" value={company} type="text" placeholder="Company Name" onChange={(e) => setCompany(e.target.value)}/>
								</div>
							</div>
							{/* <div className="full-wd">
								<div className="field-ff">
									<textarea className="ct-field" placeholder="Message"></textarea>
								</div>
							</div> */}
							<div className="full-wd">
								<div className="field-ff">
									<button className="sbt-fm-btn" onClick={handleSubmit}>Submit Now</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
  )
}
