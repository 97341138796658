import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { REACT_APP_URL } from '../../../../config';
import axios from 'axios';
import Swal from 'sweetalert2'

const AutomateSection = () => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState('')
  
  const handleSubmit=async(e: React.FormEvent)=>{
    e.preventDefault();
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const phoneNumberRegex = /^\d{10,14}$/;
    const isValidEmail = emailRegex.test(email);
    const isValidPhone = phoneNumberRegex.test(phone);
    if(name === ''){
      Swal.fire('Please enter name!')
    }else if(email === ''){
      Swal.fire('Please enter email!')
    }else if(!isValidEmail){
      Swal.fire('Please enter valid email!')
    }else if(!isValidPhone){
      Swal.fire('Please enter valid phone!')
    }else if(phone === ''){
      Swal.fire('Please enter phone!')
    }else if(company === ''){
      Swal.fire('Please enter company name!')
    }else{
      let data ={
        name: name,
          email: email,
          phone: phone,
          company: company,
      }
      console.log(data,'data')
      try {
        const response = await axios.post(`${REACT_APP_URL}/leads/admin/addLead`, data);
        console.log(response,'response')
        if (response.data.status === 201) {
          Swal.fire(response.data.message)
          setName("");
          setEmail("");
          setPhone("");
          setCompany("");
        }
      } catch (error) {
        Swal.fire('Something went wrong!')
      }
    }
  }
  
  
  return (
    <div className="automate-section">
      <div className="container">
        <div className="row-flex align-items-center">
          <div className="col-md-6">
            <div className="auto-left">
              <h2>
                Automate your Property management Rental Process and all
                operation online.
              </h2>
              <p style={{ color: "black" }}>
                Get one stop online digital solution for maintenance,
                appointment with prospects and current tenants, online
                application and approval, online text messages and email
                notifications and much more.
              </p>
              <div className="button-grups">
                <Link className="btn orange-btn" to="/contact-us">
                  Sign Up Now
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_19_66)">
                      <path
                        d="M12.5 4.16675L11.0312 5.6355L16.8437 11.4584H4.16666V13.5417H16.8437L11.0312 19.3647L12.5 20.8334L20.8333 12.5001L12.5 4.16675Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_19_66">
                        <rect width="25" height="25" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>
                <Link className="btn blue-btn" to="/contact-us">
                  Book a Demo
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_19_66)">
                      <path
                        d="M12.5 4.16675L11.0312 5.6355L16.8437 11.4584H4.16666V13.5417H16.8437L11.0312 19.3647L12.5 20.8334L20.8333 12.5001L12.5 4.16675Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_19_66">
                        <rect width="25" height="25" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="auto-form">
              <h3>Get Started</h3>
              <p>Discover the future of property management</p>
              <form
              >
                <div className="form-field">
                  <input className="ct-field" value={name} type="text" placeholder="Name" onChange={(e) => setName(e.target.value)}/>
                </div>
                <div className="form-field">
                  <input className="ct-field" value={email} type="email" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div className="form-field">
                  <input className="ct-field" value={phone} type="tel" placeholder="Phone Number" onChange={(e) => setPhone(e.target.value)}/>
                </div>
                <div className="form-field">
                  <input className="ct-field" value={company} type="text" placeholder="Company Name" onChange={(e) => setCompany(e.target.value)}/>
                </div>
                <div className="form-field">
                  <button className="sbt-btn orange-btn" onClick={handleSubmit}>Submit <img src="images/arrow-forward.svg" alt="Arrow" /></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomateSection;
