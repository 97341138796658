import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Blog } from "../../../../interfaces/Blog";
import DOMPurify from "dompurify";
import { REACT_APP_URL } from "../../../../config";

const BlogListing = () => {
  const [blogs, setBlogs] = useState<Blog[]>([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_URL}/blogs/rentdigi/fetch_all_blog`
        );
        if (response.ok) {
          const res = await response.json();
          setBlogs(res.data);
        } else {
          console.error("Error fetching blogs:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  const truncateContent = (content: string, maxLength: number) => {
    if (content.length > maxLength) {
      return content.substring(0, maxLength) + " ...";
    }
    return content;
  };

  return (
    <div className="blog-listing cmn-padd">
      <div className="container">
        <div className="head-cmn">
          <h2>Our Latest Blogs</h2>
          <div className="cmn-brd">
            <span className="fst-brd"></span>
            <span className="secend-brd"></span>
          </div>
          <p style={{ color: "black" }}>
            Insights, Tips, and Inspiration: Unlocking the Secrets to Successful
            Property Maintenance
          </p>
        </div>
        <div className="row-flex">
          {blogs.map((blog) => (
            <div className="col-med-3" key={blog._id}>
              <div className="blg-ls">
                <div className="blg-ls-img">
                  <Link to={`/blog-detail/${blog.slug}`}>
                    <img src={blog.image["0"]} alt={`Blog ${blog._id}`} />
                  </Link>
                </div>
                <div className="blg-ls-content">
                  <span className="auth-m">{blog.author["0"].name}</span>
                  <h2>{blog.title}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: truncateContent(
                        DOMPurify.sanitize(blog.content),
                        130
                      ),
                    }}
                  ></div>

                  <Link className="rdo-more" to={`/blog-detail/${blog.slug}`}>
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogListing;
