import React from "react";
import { Link } from "react-router-dom";

export const HeroSection = () => {
  return (
    <div
      className="hero-section"
      style={{ backgroundImage: "url('images/hero-bg.svg')" }}
    >
      <div className="container">
        <div className="hero-inner">
          <h1>Rentdigicare: Elevating Rentals, Caring for You, Every Step!</h1>
          <div className="video-container">
            <video
              className="responsive-video"
              width="100%"
              height="auto"
              controls
              autoPlay={false}
            >
              <source src="videos/Hero-Video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <p>
            We strive to elevate the rental experience for landlords and tenants
            alike, setting new industry standards through innovative solutions
            and unwavering commitment to excellence.
          </p>
          <Link className="btn blue-btn" to="/contact-us">
            Get Started
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <g clipPath="url(#clip0_19_66)">
                <path
                  d="M12.5 4.16675L11.0312 5.6355L16.8437 11.4584H4.16666V13.5417H16.8437L11.0312 19.3647L12.5 20.8334L20.8333 12.5001L12.5 4.16675Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_19_66">
                  <rect width="25" height="25" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};
