import OwlCarousel from "react-owl-carousel";

export const LandingSlider = () => {
  return (
    <div className="landing-slider-section">
      <div className="owl-slider">
      <OwlCarousel
        className="landing-carousel owl-carousel"
        autoplay={true}
        rewind={true}
        margin={0}
        responsiveClass={true}
        autoplayTimeout={7000}
        smartSpeed={800}
        dots={false}
        loop
        nav={false}
        responsive={{
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1024: {
            items: 1
          },
          1366: {
            items: 1
          },
        }}
      >
          <div
            className="item"
            style={{ backgroundImage: "url('images/slide-1.jpg')" }}
          >
            <div className="container">
              <div className="landing-bx">
                <h3>
                  Turning Properties into Profits: Where Ownership Meets
                  Excellence
                </h3>
              </div>
            </div>
          </div>
          <div
            className="item"
            style={{ backgroundImage: "url('images/slide-2.jpg')" }}
          >
            <div className="container">
              <div className="landing-bx">
                <h3>
                  Relax, We've Got Your Property Covered: Your Trustworthy
                  Managers
                </h3>
              </div>
            </div>
          </div>
          <div
            className="item"
            style={{ backgroundImage: "url('images/slide-3.jpg')" }}
          >
            <div className="container">
              <div className="landing-bx">
                <h3>
                  Unlocking Potential, Maximizing Returns: Your Property, Our
                  Priority
                </h3>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
};
