import React from "react";

const AboutSectionBottom = () => {
  return (
    <>
      <div className="abt_section cmn-padd" style={{ background: "#F7F8F9" }}>
        <div className="container">
          <div className="row-flex align-items-center column-reversed-mb">
            <div className="col-md-6">
              <div className="cmn-left pd-right">
                <h2>Expert Rent Property Services at Your Fingertips</h2>
                <div className="cmn-brd justify-start">
                  <span className="fst-brd"></span>
                  <span className="secend-brd"></span>
                </div>
                <p>
                  At Rentdigicare, We can help you to create modern property
                  management websites to get the leads for rental prospects and
                  streamline your property management operations
                </p>
                <ul className="ab_list">
                  <li>Property Listings</li>
                  <li>IDX/MLS Integration</li>
                  <li>Live Chat/Newsletter Form Integration</li>
                  <li>Advanced search</li>
                  <li>User Management (Register/Login)</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="cmn-right">
                <img src="images/fingertips.png" alt="Fingertips" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="abt_section cmn-padd">
        <div className="container">
          <div className="row-flex align-items-center">
            <div className="col-md-6">
              <div className="cmn-right">
                <img src="images/Group 8@2x.png" alt="Maintenance" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="cmn-left pd-left">
                <h2>Complete Solution For Rental Management</h2>
                <div className="cmn-brd justify-start">
                  <span className="fst-brd"></span>
                  <span className="secend-brd"></span>
                </div>
                <p>
                  <strong>Vacancy Management:</strong> Efficiently manage
                  vacancies by tracking available units, scheduling showings,
                  and processing applications to minimize downtime.
                </p>
                <p>
                  <strong>Vendor Management:</strong> Streamline vendor
                  management by communicating and tracking work orders with
                  maintenance contractors and suppliers.
                </p>
                <p>
                  <strong>Tenant Screening:</strong> Streamline the tenant
                  screening process with comprehensive background checks, credit
                  history verification, and reference checks to find reliable
                  tenants.
                </p>
                <p>
                  <strong>Communication Tools:</strong> Facilitate seamless
                  communication between property managers, landlords, and
                  tenants through in-app messaging, SMS, and email
                  notifications.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="abt_section cmn-padd" style={{ background: "#F7F8F9" }}>
        <div className="container">
          <div className="row-flex align-items-center column-reversed-mb">
            <div className="col-md-6">
              <div className="cmn-left pd-right">
                <h2>Digital Document Management & Automated Reminders:</h2>
                <div className="cmn-brd justify-start">
                  <span className="fst-brd"></span>
                  <span className="secend-brd"></span>
                </div>
                <p>
                  Set up automated reminders for important milestones such as
                  lease renewals and maintenance appointments to ensure timely
                  actions. Organize and manage all rental-related documents,
                  such as leases and contracts, securely and effortlessly.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="cmn-right">
                <img src="images/Group 9@2x.png" alt="Fingertips" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="abt_section cmn-padd">
        <div className="container">
          <div className="row-flex align-items-center">
            <div className="col-md-6">
              <div className="cmn-right">
                <img src="images/Group 10@2x.png" alt="Maintenance" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="cmn-left pd-left">
                <h2>Performance analytics & Financial reporting:</h2>
                <div className="cmn-brd justify-start">
                  <span className="fst-brd"></span>
                  <span className="secend-brd"></span>
                </div>
                <p>
                  Gain valuable insights into your rental property's performance
                  through analytics and reporting features to make data-driven
                  decisions Generate detailed financial reports, including
                  income statements and expense tracking, for simplified
                  accounting and financial management.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="abt_section cmn-padd" style={{ background: "#F7F8F9" }}>
        <div className="container">
          <div className="row-flex align-items-center column-reversed-mb">
            <div className="col-md-6">
              <div className="cmn-left pd-right">
                <h2>Simplify Rental Management with Our Online Calendar:</h2>
                <div className="cmn-brd justify-start">
                  <span className="fst-brd"></span>
                  <span className="secend-brd"></span>
                </div>
                <p>
                  Effortlessly manage your rental properties with our
                  user-friendly online calendar. Our feature-rich platform is
                  designed to simplify your rental management tasks and
                  streamline your operations. With our online calendar, you can
                  stay organized, maximize occupancy, and provide a seamless
                  booking experience for your guests.
                </p>
                <p>
                  <strong>Real-Time Availability:</strong> Stay updated on the
                  availability of your rental homes in real-time. Our online
                  calendar provides instant visibility into which properties are
                  booked and which are available on any given date. Say goodbye
                  to manual tracking and avoid double bookings.
                </p>
                <p>
                  <strong>Easy Booking Management:</strong> Our online calendar
                  simplifies the booking process, allowing you to manage
                  reservations with ease. Add, modify, or cancel bookings
                  effortlessly, ensuring accurate scheduling and reducing the
                  risk of errors. With just a few clicks, you can stay on top of
                  your rental property's occupancy.
                </p>
                <p>
                  <strong>Customizable Rules and Restrictions:</strong> Tailor
                  the booking rules and restrictions to your specific
                  requirements. Set minimum and maximum stay durations, define
                  check-in and check-out times, and establish booking
                  restrictions for certain dates or seasons. Maintain control
                  over your rental property's scheduling while accommodating
                  your guests' needs.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="cmn-right">
                <img src="images/Group 11@2x.png" alt="Fingertips" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSectionBottom;
