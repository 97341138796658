import React from "react";

export const PrivacyPolicy = () => {
  return (
    <div className="abt_section cmn-padd">
      <div className="container">
        <div className="head-cmn">
          <h2>Privacy Policy</h2>
          <div className="cmn-brd">
            <span className="fst-brd"></span>
            <span className="secend-brd"></span>
            <span className="fst-brd"></span>
            <span className="secend-brd"></span>
          </div>
        </div>
        <div style={{ marginTop: "15px", textAlign: "left" }}>
          <strong>
            <p style={{ color: "black" }}>
              At Rentdigi Care, trust is very important to us. We want you to
              know exactly what information we collect and how we use it, so you
              can rest easy that your details are safe in our hands. We've used
              simple and direct language in this privacy policy, so it's easy to
              read and understand.
            </p>
          </strong>
          <p>
            <strong style={{ color: "black" }}>
              <li>Information we collect:</li>
            </strong>
            <br />
            In order to help connect landlords and tenants, we will need to
            collect some information and personal details from you or your
            business. This information will be securely stored at all times.
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>
                Information we collect from tenants may include (but is not
                limited to):
              </li>
            </strong>
            <br />
            <ul>
              <li>
                Name, age, gender, marital status, and details of children,
                dependents, and pets.
              </li>
              <li>Driver's license, or other valid photo identification.</li>
              <li>Credit history and rental history.</li>
              <li>Names and contact information for references.</li>
              <li>Employment information.</li>
              <li>
                Contact information including phone number, address, email
                address, and alternate contact Payment information.
              </li>
            </ul>
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>
                Information we collect from landlords may include (but is not
                limited to):
              </li>
            </strong>
            <br />
            <ul>
              <li>
                Business name, contact name and title, incorporation
                information.
              </li>
              <li>Business documents and verification.</li>
              <li>Business documents and verification.</li>
              <li>
                Contact information including phone number, address, email
                address, and alternate contact.
              </li>
              <li>Payment information.</li>
              <li>Leasing details and information.</li>
              <li>Property details and information</li>
            </ul>
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>How we use the information we collect:</li>
            </strong>
            <br />
            The information we collect is used primarily to facilitate the
            transfer of information and documents needed for rental agreements,
            insurance coverage, and other services offered by Rentdigi. Care
            Other ways we use your information pertain to improving our services
            and operations, and for the maintenance of internal records. We will
            also use your information to connect you to other partners and
            companies that we feel could benefit you.
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>Cookies and how we use them:</li>
            </strong>
            <br />A cookie is a small file placed on your computer's hard drive.
            Cookies allow our website to identify your computer and store your
            preferences for customized functions and content. Cookies also allow
            us to see which pages you are viewing on our site, so we can better
            serve you and meet your needs.
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>We may use cookies to:</li>
            </strong>
            <br />
            <ul>
              <li>
                Track our web traffic for analytic and improvement purposes.
              </li>
              <li>Check if you are logged into the site.</li>
              <li>Test content on our site.</li>
              <li>Store information about your preferences.</li>
              <li>Recognize you when you return to our website.</li>
            </ul>
            <p>
              Cookies do not provide us with access to your computer or
              information. They are simply records of your interactions with our
              site. You can set your web browser setting to disallow the use of
              cookies for our site.
              <br />
              However, be aware that this may affect site functionality, and
              make certain pages unavailable.
            </p>
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>Sharing and disclosure:</li>
            </strong>
            <br />
            Rentdigi Care is a team of service providers streamlining the rental
            process. In addition to sharing information between tenants and
            landlords, we frequently share information with our service
            providers.
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>Our service partners include (but are not limited to):</li>
            </strong>
            <br />
            <ul>
              <li>Frogbox.</li>
              <li>Scotiabank.</li>
              <li>Rentsync.</li>
              <li>New Era Insurance.</li>
              <li>U-Haul.</li>
              <p>
                We may also share your information with third parties that
                provide services we think you will find valuable or useful.
              </p>
            </ul>
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>Payments:</li>
            </strong>
            <br />
            Your payment information and details will never be disclosed to
            third parties or to our partners without your permission. Only
            approved and scheduled payments will be processed using your payment
            information. If you require a change to payments at any time, please
            contact us immediately to resolve the situation.
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>Safety and compliance with the law:</li>
            </strong>
            <br />
            In cases where the law requires the sharing of information and
            details we have collected from our clients, we will comply with
            local authorities.
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>Emails:</li>
            </strong>
            <br />
            From time to time, we may send you emails to show you our new
            services, products, offers, or features. You may unsubscribe from
            the mailing list at any time by contacting us, but you could risk
            losing out on some amazing deals and offers.
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>Widgets, links, and APIs:</li>
            </strong>
            <br />
            Our site and the listings on it may link to other sites. Our site
            does not govern how information is tracked or stored when it is
            submitted to linked sites, or have any control over how these sites
            use cookies. We strongly encourage you to review the privacy
            policies on the website you have clicked on before providing your
            information or personal details.
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>Google API Services User Data Policy Compliance:</li>
            </strong>
            <br />
            In compliance with the Limited Use Policy, we affirm that our app
            adheres to the Google API Services User Data Policy. Information
            received from Google APIs will only be used and transferred as per
            these guidelines. For further details, please refer to the Google
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy"
              style={{
                textDecoration: "none",
                color: "#007bff",
                fontWeight: "bold",
                marginLeft: "0.5rem",
              }}
            >
              API Services User Data Policy
            </a>
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>Account changes:</li>
            </strong>
            <br />
            By using our site or services, you are agreeing to the collection,
            use, and disclosure of your information. Please notify us if you
            wish to make changes to the way your information is collected, used,
            or disclosed. Additionally, it is important that your information is
            kept up to date on the site. If there are any changes to your
            information, or you require help changing your information, please
            contact us.
          </p>
          <p>
            <strong style={{ color: "black" }}>
              <li>Contact:</li>
            </strong>
            <br />
            If you have any questions about our services, websites, agreements,
            or this privacy policy, please don't hesitate to reach out to us at
            help@rentdigicare.com. We are happy to answer any questions.
          </p>
        </div>
      </div>
    </div>
  );
};
