import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { REACT_APP_LOGIN_URL } from "../../../config";
import { toAbsoluteUrl } from "../../../utils/helpers";

export const Footer = () => {
  useEffect(() => {
    const handleLinkClick = (e: any) => {
      if (e.currentTarget.getAttribute("href") === "javascript:void(0)") {
        return;
      }
      window.scrollTo({ top: 0 });
    };

    const links = document.querySelectorAll(".ft-list a");
    links.forEach((link) => {
      link.addEventListener("click", handleLinkClick);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", handleLinkClick);
      });
    };
  }, []);

  return (
    <div
      className="footer"
      style={{ backgroundImage: "url('images/footer-bg.svg')" }}
    >
      <div className="container">
        <div className="row-flex">
          <div className="ftr-cmn-wd ftr-one-wd">
            <div className="ftr-bx">
              <img src={toAbsoluteUrl("images/footer-logo.svg")} alt="" />
              <div className="ftr-social">
                <h4>Connect with us</h4>
                <ul className="d-flex flex-wrap">
                  <li>
                    <Link to="https://www.facebook.com/Rentdigi">
                      <i className="fab fa-facebook" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.instagram.com/rentdigi/">
                      <i className="fab fa-instagram" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.youtube.com/@rentdigi2735">
                      <i className="fab fa-youtube" aria-hidden="true"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="ftr-cmn-wd">
            <div className="ftr-bx">
              <h2>Explore</h2>
              <ul className="ft-list">
                <li>
                  <a href={REACT_APP_LOGIN_URL} target="_blank">
                    Login
                  </a>
                </li>
                <li>
                  <Link to="/blog">Blogs</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="ftr-cmn-wd">
            <div className="ftr-bx">
              <h2>Locations</h2>
              <ul className="ft-list-locations">
                <li>
                  <Link to="javascript:void(0)">Edmonton</Link>
                </li>
                <li>
                  <Link to="javascript:void(0)">Alberta</Link>
                </li>
                <li>
                  <Link to="javascript:void(0)">Canada</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="ftr-cmn-wd">
            <div className="ftr-bx">
              <h2>Quick Links</h2>
              <ul className="ft-list">
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-of-service">Terms of Service</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
