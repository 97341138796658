import React from "react";

export const CmnHero = () => {
  const backgroundImageUrl = "images/blog-bg.jpg";

  return (
    <div
      className="cmn-hero"
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <div className="container">
        <div className="cmn-hero-inner">
          <h2>Get Latest Updates & Tips</h2>
        </div>
      </div>
    </div>
  );
};
