import { HeroSection } from "./Subcomponents/HeroSection";
import AutomateSection from "./Subcomponents/AutomateSection";
import { LandingSlider } from "./Subcomponents/LandingSlider";
import ServicesSection from "./Subcomponents/ServicesSection";
import { CompanyMotoSection } from "./Subcomponents/CompanyMotoSection";
import { ManagementSection } from "./Subcomponents/ManagementSection";
import { DescriptorSection } from "./Subcomponents/DescriptorSection";
import { CounterSection } from "./Subcomponents/CounterSection";
import { Testimonials } from "./Subcomponents/Testimonials";
import { Sponser } from "./Subcomponents/Sponser";
import { Helmet } from "react-helmet";
import Agenda from "../agenda";

export const Landing = () => {
  return (
    <>
      <Helmet>
        <title>
          Best Property Management Services in Alberta | Rentdigicare
        </title>
        <meta
          name="description"
          content="Searching for top-notch property management services in Alberta? We&#39;re a trusted property management company, dedicated to finding best rental properties, house contractors &amp; making your life easier."
        />
        <meta
          name="keywords"
          content="Professional Property Management Services, find rental property, Alberta, find best rental properties, house contractors near me"
        />
        <meta
          name="keywords"
          content="Rentdigicare provides comprehensive, professional, and personalized property management services to property owners and tenants."
        />
      </Helmet>

      <LandingSlider />
      <ServicesSection />
      {/* <Agenda /> */}
      <HeroSection />
      <AutomateSection />
      <CompanyMotoSection />
      <ManagementSection />
      <DescriptorSection />
      <CounterSection />
      <Testimonials />
      <Sponser />
    </>
  );
};
