import { Header } from './Subcomponents/Header';
import { Newsletter } from './Subcomponents/Newsletter';
import { Footer } from './Subcomponents/Footer';
import { Copyright } from './Subcomponents/Copyright';
import { Outlet } from 'react-router-dom'; // Import the Outlet component

export const Layout = () => {
  return (
    <>
      <div className="wrapper">
        <Header />
        <Outlet /> {/* Render the child components based on the route */}
      </div>
      <Newsletter />
      <Footer />
      <Copyright />
    </>
  );
};
