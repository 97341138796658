import React from 'react'

export const Copyright = () => {
  return (
    <div className="copyright">
		<div className="container">
			<p>&copy;Copyrights 2023 - www.rentdigicare.com - All rights reserved</p>
		</div>
	</div>
  )
}
