import { CmnHero } from "./Subcomponents/CmnHero";
import AboutSection from "./Subcomponents/AboutSection";
import { Helmet } from "react-helmet";

export const About = () => {
  return (
    <>
      <Helmet>
        <title>Top Rental Property Management Services in Alberta</title>
        <meta
          name="description"
          content="Rentdigicare: Your Trusted Choice for Best Rental Property Management, Contractors and Property Management Services in Alberta. Find Your Dream Property with Us!"
        />
        <meta
          name="keywords"
          content="rental property management, property management services, home rental property management"
        />
      </Helmet>
      <CmnHero />
      <AboutSection />
    </>
  );
};
