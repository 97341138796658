import OwlCarousel from "react-owl-carousel";

export const Sponser = () => {
  const sponsorImages = [
    "images/FCS_logo.png",
    "images/CF_logo.png",
    "images/BP_logo.png",
    "images/SAM_logo.png",
    "images/PK-logo.png",

    "images/logo8@2x.png",
    "images/lofo 5@2x.png",
    "images/logo 1@2x.png",
    "images/logo 3@2x.png",
    "images/logo2@2x.png",
    "images/logo4@2x.png",
    "images/logo6@2x.png",
    "images/logo7@2x.png",
  ];

  const owlOptions = {
    loop: true,
    autoplay: true,
    margin: 0,
    responsiveClass: true,
    autoplayTimeout: 1000,
    smartSpeed: 900,
    dots: false,
    nav: false,
    responsive: {
      0: {
        items: 2,
        center: true,
      },
      768: {
        items: 4,
      },
      992: {
        items: 5,
      },
    },
  };

  return (
    <div className="sponser-section cmn-padd">
      <div className="container">
        <div className="head-cmn sponser-head">
          <h2>Trusted by forward-thinking enterprises globally</h2>
          <div className="cmn-brd">
            <span className="fst-brd"></span>
            <span className="secend-brd"></span>
          </div>
        </div>
        <div className="sponser-main">
          <div className="container">
            <OwlCarousel className="" {...owlOptions}>
              {sponsorImages.map((image, index) => (
                <div className="sponser-wd" key={index}>
                  <img
                    src={image}
                    alt={`Sponsor ${index}`}
                    style={{ width: "100px", height: "auto" }}
                  />
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};
