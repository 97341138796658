import { CmnHero } from "./Subcomponents/CmnHero";
import { ContactSection } from "./Subcomponents/ContactSection";
import { MapSection } from "./Subcomponents/MapSection";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

export const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Contact us - Property Management & Maintenance Services!</title>
        <meta
          name="description"
          content="Experience Stress-Free Apartment Property Management services in Edmonton with Rentdigicare. We offer top-tier property management services, online rental and leasing services, & we're your trusted partner."
        />
        <meta
          name="keywords"
          content="apartment property management Edmonton, apartment management services Alberta, leasing property management services, Online rental and leasing services, apartment property management companies"
        />
      </Helmet>
      <CmnHero />
      <ContactSection />
      <MapSection />
    </>
  );
};
