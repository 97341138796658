import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
interface ServiceItemProps {
  imgSrc: string;
  title: string;
}
const ServiceItem: React.FC<ServiceItemProps> = ({ imgSrc, title }) => (
  <div className="item">
    <div className="srv-bx">
      <div className="srv-bx-img">
        <img src={imgSrc} alt={title} />
      </div>
      <h4>{title}</h4>
    </div>
  </div>
);

const ServiceSection = () => (
  <div className="all-service-section cmn-padd">
    <div className="container">
      <div className="head-cmn">
        <h2>
          Efficiency Unleashed: Enabling Property Managers, Empowering
          Landlords, and Connecting Contractors with Rentdigicare!
        </h2>
        <div className="cmn-brd">
          <span className="fst-brd"></span>
          <span className="secend-brd"></span>
        </div>
        <p style={{color: "black", fontSize: "18px"}}>
          Rentdigicare provides comprehensive, professional, and personalized{" "}
          <br />
          services to property owners and tenants.
        </p>
      </div>
      <OwlCarousel
        className="all-service-carousel owl-carousel"
        autoplay={true}
        rewind={true}
        margin={20}
        responsiveClass={true}
        autoplayTimeout={7000}
        smartSpeed={800}
        dots={false}
        nav={false}
        loop
        responsive={{
          0: {
            items: 1,
            margin: 10,
          },

          600: {
            items: 2,
            margin: 10,
          },

          1024: {
            items: 4,
          },

          1366: {
            items: 6,
          },
        }}
      >
        <ServiceItem imgSrc="images/srv-1.svg" title="Legal Compliance" />
        <ServiceItem imgSrc="images/srv-2.svg" title="Property Maintenance" />
        <ServiceItem
          imgSrc="images/srv-3.svg"
          title="Marketing & Advertising"
        />
        <ServiceItem imgSrc="images/srv-4.svg" title="Tenant Screening" />
        <ServiceItem imgSrc="images/srv-5.svg" title="Financial Management" />
        <ServiceItem imgSrc="images/srv-6.svg" title="Industry Expertise" />
        <ServiceItem imgSrc="images/srv-5.svg" title="Financial Management" />
      </OwlCarousel>
      <div className="get-more-bx text-center">
        <Link className="btn orange-btn" to="/contact-us">
          Get Started
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <g clipPath="url(#clip0_19_66)">
              <path
                d="M12.5 4.16675L11.0312 5.6355L16.8437 11.4584H4.16666V13.5417H16.8437L11.0312 19.3647L12.5 20.8334L20.8333 12.5001L12.5 4.16675Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_19_66">
                <rect width="25" height="25" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </div>
    </div>
  </div>
);

export default ServiceSection;
