import React from "react";

export const CmnHero = () => {
  const backgroundImageUrl = 'url("images/contact-bg.jpg")';

  return (
    <div className="cmn-hero" style={{ backgroundImage: backgroundImageUrl }}>
      <div className="container">
        <div className="cmn-hero-inner">
          <h2>We're Happy To Serve You</h2>
        </div>
      </div>
    </div>
  );
};
