import React, { useState } from "react";
import { Link } from "react-router-dom";
import { REACT_APP_LOGIN_URL } from "../../../config";
import { toAbsoluteUrl } from "../../../utils/helpers";

export const Header = () => {
  const [isMenuShown, setIsMenuShown] = useState(false);

  return (
    <div className="header">
      <div className="top-header">
        <div className="container">
          <div className="row-flex justify-space-between">
            <div className="tp-left d-flex flex-wrap">
              <p>
                Call Us: <a href="tel:+1-844-999-3444">(+1) 844 999 3444</a>
              </p>
              <ul className="d-flex flex-wrap tp-social-link">
                <li>
                  <a href="https://www.facebook.com/Rentdigi">
                    <img
                      src={toAbsoluteUrl("images/Facebook F.svg")}
                      alt="Facebook"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/rentdigi/">
                    <img
                      src={toAbsoluteUrl("images/Instagram.svg")}
                      alt="Instagram"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@rentdigi2735">
                    <img
                      src={toAbsoluteUrl("images/youtube.svg")}
                      alt="Youtube"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="middle-header">
        <div className="container">
          <div className="row-flex justify-space-between align-items-center">
            <div className="logo">
              <Link to="/">
                <img src={toAbsoluteUrl("images/logo.svg")} alt="Logo" />
              </Link>
            </div>
            <div className="menu-box">
              <button
                type="button"
                className="toggle-btn"
                onClick={() => setIsMenuShown((prevState) => !prevState)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="96"
                  height="96"
                  viewBox="0 0 96 96"
                  fill="none"
                >
                  <g clip-path="url(#clip0_235_35)">
                    <path
                      d="M36 72H84V64H36V72ZM12 24V32H84V24H12ZM36 52H84V44H36V52Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_235_35">
                      <rect width="96" height="96" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <div
                className={`menu-items ${isMenuShown ? "show-menu" : ""}`}
                onClick={() => setIsMenuShown((prevState) => !prevState)}
              >
                {" "}
                <ul className="main-menu">
                  <li>
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li>
                    <Link to={"/about"}>About</Link>
                  </li>
                  <li>
                    <Link to={"/services"}>Services</Link>
                  </li>
                  <li>
                    <Link to={"/blog"}>Blog</Link>
                  </li>
                  <li>
                    <Link to={"/contact-us"}>Contact Us</Link>
                  </li>
                  <li>
                    <Link to={"/privacy-policy"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <a href={REACT_APP_LOGIN_URL} target="_blank">
                      Login
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
